import type { ContentItemInterface } from '@/interfaces';
import { MetricItemNumber, MetricItemSubtext, MetricItemWrapper } from './Metrics.styles';

const Metrics: React.FC<ContentItemInterface> = ({ title, subTitle }) => {
  return (
    <MetricItemWrapper>
      <MetricItemNumber>{title}</MetricItemNumber>
      <MetricItemSubtext>{subTitle}</MetricItemSubtext>
    </MetricItemWrapper>
  );
};

export default Metrics;
