import styled from 'styled-components';
import { Typography } from '@/components';
import { TextStyles } from '@/constants';

const CENTER_ITEM_WIDTH = 256;
const OTHER_ITEMS_WIDTH = 240;

export const MetricItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  padding: 0 ${({ theme }) => theme.spacing[8]};

  &:nth-child(odd) {
    padding-left: 0;
  }

  &:nth-child(even) {
    padding-right: 0;
  }

  &:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 0 ${({ theme }) => theme.spacing[16]};
    max-width: ${OTHER_ITEMS_WIDTH}px;

    &:first-child, &:nth-child(2n + 4) {
      padding-left: 0;
      padding-right: ${({ theme }) => theme.spacing[16]};
      max-width: ${OTHER_ITEMS_WIDTH}px;
    }

    &:nth-child(2),
    &:nth-child(3n - 1),
    &:last-child:nth-child(3n + 1) {
      padding: 0 ${({ theme }) => theme.spacing[16]};
      max-width: ${CENTER_ITEM_WIDTH}px;
    }

    &:last-child, &:nth-child(3n) {
      padding-right: 0;
      padding-left: ${({ theme }) => theme.spacing[16]};
      max-width: ${OTHER_ITEMS_WIDTH}px;
    }

    :nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
`;

export const MetricItemNumber = styled(Typography).attrs({
  variant: TextStyles['Large Text Display'],
})`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  text-align: center;
`;

export const MetricItemSubtext = styled(Typography).attrs({
  variant: TextStyles['Heading 4'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  text-align: center;
`;
